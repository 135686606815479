<template>
  <div>
    <table-page dataName="users"
                ref="table"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :search="search"
                :request="businessRecord">
      <template slot="button">
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="exportData()">导出参与数据</el-button>

        <FileUpload type="primary"
                    class="mr-15"
                    @on-success="$refs.table.fetch()"
                    buttonType="icon"
                    :params="{import_type:'business_recommend_data',carry_data:{activity_id:$route.query.id}}">导入办理成功数据</FileUpload>

      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="gender"
                       show-overflow-tooltip
                       label="性别">
        <template slot-scope="scope">
          <span>{{`${scope.row.gender === 1?'男':'女'}`}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="decorate_address"
                       show-overflow-tooltip
                       label="装修地址">
      </el-table-column>
      <el-table-column prop="ptel"
                       show-overflow-tooltip
                       label="上级用户手机号">
      </el-table-column>
      <el-table-column prop="is_transaction"
                       show-overflow-tooltip
                       label="是否已办理业务">
        <template slot-scope="scope">
          <span>{{`${scope.row.is_transaction?'是':'否'}`}}</span>
        </template>
      </el-table-column>
    </table-page>

  </div>

</template>

<script>
import FileUpload from '@/components/FileUploadAsync'
import TablePage from '@/components/TablePage'
import { businessRecord } from '@/api/market'
export default {
  name: 'BusinessRecords',
  data () {
    return {
      businessRecord,
      dialogVisible: false,
      luckyQrcodesExport_url: '',
      search: []
    }
  },
  components: {
    TablePage,
    FileUpload
  },
  methods: {
    fetchSuccess (e) {
      this.luckyQrcodesExport_url = e.luckyQrcodesExport_url
      this.luckyLogExport_url = e.luckyLogExport_url
    },
    exportData () {
      window.open(`/mall/admin/activity/business/recommend/user/export/data?activity_id=${this.$route.query.id}`, '_blank')
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.el-dialog-container {
  div {
    margin-top: 10px;
  }
  .el-dialog-h1 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
  }
  .el-dialog-h2 {
    font-size: 14px;
    font-weight: bolder;
    color: #494949;
  }
}
</style>
